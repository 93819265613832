export const BudgetInterval: any = {
    first: {
        id: 1,
        value: 'Moins de 2000',
        text: '< 2 000 €',
        svgRange: {
            min: 1,
            max: 1,
        },
    },
    second: {
        id: 2,
        value: 'Entre 2000 et 2500',
        text: '2 000 € - 2 500 €',
        svgRange: {
            min: 2,
            max: 4,
        },
    },
    third: {
        id: 3,
        value: 'Entre 2500 et 3000',
        text: '2 500 € - 3 000 €',
        svgRange: {
            min: 5,
            max: 7,
        },
    },
    forth: {
        id: 4,
        value: 'Plus de 3000',
        text: '> 3 000 €',
        svgRange: {
            min: 8,
            max: 8,
        },
    },
};

export const BudgetIntervalRS: any = {
    first: {
        id: 1,
        value: 'Entre 800 et 1500',
        text: '800 € - 1 500 €',
        svgRange: {
            min: 1,
            max: 1,
        },
    },
    second: {
        id: 2,
        value: 'Entre 1500 et 2000',
        text: '1 500 € - 2 000 €',
        svgRange: {
            min: 2,
            max: 4,
        },
    },
    third: {
        id: 3,
        value: 'Plus de 2000',
        text: '> 2 000 €',
        svgRange: {
            min: 5,
            max: 8,
        },
    },
};
