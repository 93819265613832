







































import Map from '@/components/Map.vue';
import Vue from 'vue';
import { Deadline } from '@/models/deadline';

export default Vue.extend({
    name: 'Main',
    components: {
        Map,
    },
    data() {
        return {
            maxSelectedDepartments: 8, //Number maximum of departments that can be selected
            disabledBelgium: false, //If we want to disable Belgium from the departments selection
            deadlineList: Deadline,
        };
    },
    methods: {
        gotToForm(deadlineId: any) {
            this.$router.push({
                name: 'Form',
                params: { deadline: deadlineId },
            });
        },
    },
    mounted() {},
});
