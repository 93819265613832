









































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import * as d3 from 'd3';
import CurrentSituationDropdown from './components/CurrentSituationDropdown.vue';
import RelationDropdown from './components/RelationDropdown.vue';
import DepartmentsDropdown from './components/DepartmentsDropdown.vue';
import Map from '@/components/Map.vue';
import Modal from '@/components/Modal.vue';
import { Deadline } from '@/models/deadline';
import { EstablishmentType } from '@/models/establishmentType';
import { BudgetInterval } from '@/models/budgetInterval';
import { BudgetIntervalRS } from '@/models/budgetInterval';
import Axios from 'axios';

export default Vue.extend({
  name: 'Form',
  components: {
    RelationDropdown,
    CurrentSituationDropdown,
    DepartmentsDropdown,
    Map,
    Modal,
  },
  data() {
    return {
      currentStep: 1,
      unsentForm: true,
      unsetFormCallback: null as any,
      showModalUnsentForm: false,
      showModalConditions: false,
      maxSelectedDepartments: 8, //Number maximum of departements that can be selected
      disabledBelgium: true, //If we want to disable Belgium from the departements selection
      establishmentType: EstablishmentType,
      budgetInterval: BudgetInterval,
      budgetIntervalRS: BudgetIntervalRS,
      selectedDepartments: [] as any[],
      selectedEstablishmentType: EstablishmentType.medical,
      selectedBudgetInterval: BudgetInterval.second,
      showSearchLayer: false,
      infosForm: {
        gender: '',
        birthName: '',
        usageName: '',
        firstName: '',
        age: '',
        zipCode: '',
        currentSituation: '',
      },
      infosFormInvalidField: [] as any,
      deadlinesList: Deadline,
      contactForm: {
        lastName: '',
        name: '',
        email: '',
        phone: '',
        relation: '',
        conditions: '',
      },
      contactFormInvalidField: [] as any,
      additionalInformation: '',
      isRunningAnimation: false,
    };
  },
  computed: {
    currentPercentage: function (): any {
      return `${(100 * this.currentStep) / 7}%`;
    },
    disableGoToStep2: function (): any {
      return !this.selectedFranceDepartments.length && !this.selectedBelgiumDepartments.length;
    },
    disableGoToStep3: function (): any {
      return !this.selectedEstablishmentType;
    },
    disableGoToStep4: function (): any {
      return !this.selectedBudgetInterval;
    },
    disableGoToStep5: function (): any {
      return (
          !this.infosForm.gender ||
          !this.infosForm.birthName ||
          !this.infosForm.firstName ||
          !this.infosForm.age ||
          !this.infosForm.zipCode ||
          !this.infosForm.currentSituation
      );
    },
    disableGoToStep6: function (): any {
      return (
          !this.contactForm.lastName ||
          !this.contactForm.name ||
          !this.contactForm.email ||
          !this.contactForm.phone ||
          !this.contactForm.conditions ||
          !this.contactForm.relation
      );
    },
    showUsageName: function (): any {
      return this.infosForm && this.infosForm.gender && this.infosForm.gender == 'Mme' ? true : false;
    },
    selectedFranceDepartments: function (): any {
      let department = this.selectedDepartments
          .filter((department) => {
            if (department.isFrance) {
              return true;
            }
          })
          .map((d) => d.uniqueId);
      return department || [];
    },
    selectedBelgiumDepartments: function (): any {
      let department = this.selectedDepartments
          .filter((department) => {
            if (!department.isFrance) {
              return true;
            }
          })
          .map((d) => d.uniqueId);
      return department || [];
    },
    selectedDeadlineText: function (): any {
      for (let deadline in this.deadlinesList) {
        let obj = this.deadlinesList[deadline];
        if (obj && obj.value == this.$route.params.deadline) {
          return obj.text;
        }
      }
    },
    selectedDepartmentsText: function (): any {
      let department = this.selectedDepartments.map((d) => `${d.value} - ${d.name}`);
      return department.join(', ');
    },
  },
  watch: {
    currentStep: function (val, oldVal) {
      if (val != oldVal) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.isRunningAnimation = true;
        if (val == 1 && this.selectedDepartments && this.selectedDepartments.length) {
          this.$nextTick(() => {
            this.selectedDepartments.forEach((department) => {
              (this.$refs.departmentMap! as any).toggleDepartement(department);
            });
          });
        }
        if (val == 3) {
          this.$nextTick(() => {
            let svg = d3
                .select('.svg-wrapper')
                .append('svg')
                .attr('id', 'budget')
                .attr('class', 'svg-budget')
                .attr('x', '0px')
                .attr('y', '0px')
                .attr('preserveAspectRatio', 'xMinYMin meet')
                .attr('viewBox', '0 0 458 215');

            this.createBudgetSvgPath(svg, 1, 'M0,163.13v38.41H48.91V159.8Q24.81,162,0,163.13Z');
            this.createBudgetSvgPath(svg, 2, 'M58.31,158.92v42.63h48.91V153Q83.22,156.46,58.31,158.92Z');
            this.createBudgetSvgPath(svg, 3, 'M116.62,151.57v50h48.91V142.78Q141.65,147.7,116.62,151.57Z');
            this.createBudgetSvgPath(
                svg,
                4,
                'M174.94,140.78v60.77h48.91V128.72Q200.12,135.31,174.94,140.78Z'
            );
            this.createBudgetSvgPath(svg, 5, 'M233.25,126v75.5h48.91V110Q258.67,118.65,233.25,126Z');
            this.createBudgetSvgPath(svg, 6, 'M291.56,106.53v95h48.91V85.38Q317.39,96.63,291.56,106.53Z');
            this.createBudgetSvgPath(
                svg,
                7,
                'M349.87,80.67V201.54h48.91V51.72A443.44,443.44,0,0,1,349.87,80.67Z'
            );
            this.createBudgetSvgPath(
                svg,
                8,
                'M408.18,45.05v156.5h48.91V0H457C443.82,15.83,427.42,30.91,408.18,45.05Z'
            );

            if (this.selectedBudgetInterval && this.selectedBudgetInterval.svgRange) {
              this.selectSvgRange(this.selectedBudgetInterval.svgRange.max, false);
            }
          });
        }

        setTimeout(() => {
          this.isRunningAnimation = false;
        }, 600);
      }
    },
    selectedBudgetInterval: function (val) {
      if (val && val.svgRange) {
        this.selectSvgRange(val.svgRange.max, true);
      }
    },
  },
  methods: {
    createBudgetSvgPath(svg: any, id: any, path: any) {
      let defaultColor = '#3298B0';

      svg.append('path')
          .attr('d', path)
          .attr('id', `budgetPathId_${id}`)
          .style('fill', defaultColor)
          .style('cursor', 'pointer')
          .on('mouseover', () => {
            this.selectSvgRange(id, true);
          })
          .on('mouseout', () => {
            if (this.selectedBudgetInterval && this.selectedBudgetInterval.svgRange) {
              this.selectSvgRange(this.selectedBudgetInterval.svgRange.max, true);
            } else {
              this.selectSvgRange(0, true);
            }
          })
          .on('click', () => {
            for (let budget in this.budgetInterval) {
              let element = this.budgetInterval[budget];
              if (element && id >= element.svgRange.min && id <= element.svgRange.max) {
                this.selectedBudgetInterval = element;
              }
            }
          })
          .on('click', () => {
            for (let budget in this.budgetIntervalRS) {
              let element = this.budgetIntervalRS[budget];
              if (element && id >= element.svgRange.min && id <= element.svgRange.max) {
                this.selectedBudgetInterval = element;
              }
            }
          });
    },
    selectSvgRange(max: any, hasTransition: boolean) {
      let defaultColor = '#3298B0';
      let overColor = '#0B5177';

      for (let i = 8; i != 0; i--) {
        let color = i <= max ? overColor : defaultColor;
        d3.select(`#budgetPathId_${i}`)
            .transition()
            .duration(hasTransition ? 250 : 0)
            .style('fill', color);
      }
    },
    closeUnsentForm() {
      this.showModalUnsentForm = false;
      this.unsetFormCallback = false;
    },
    departmentClicked(selectedDepartment: any) {
      if (
          selectedDepartment.isFrance &&
          this.selectedFranceDepartments.indexOf(selectedDepartment.uniqueId) != -1
      ) {
        this.selectedDepartments = this.selectedDepartments.filter(
            (department) => department.uniqueId !== selectedDepartment.uniqueId
        );
        (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
        return;
      }

      if (this.selectedDepartments.length < this.maxSelectedDepartments) {
        this.selectedDepartments.push(selectedDepartment);
        (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
        return;
      }
    },
    goToStep(stepNumber: number) {
      if (this.currentStep == 4 && this.showSearchLayer) {
        return false;
      } else {
        this.showSearchLayer = false;
      }

      this.currentStep = stepNumber;
    },
    goToStep5() {
      this.infosFormInvalidField = [];
      if (!this.disableGoToStep5) {
        if (
            !Number.isInteger(this.infosForm.age) &&
            !(parseInt(this.infosForm.age) >= 1 && parseInt(this.infosForm.age) <= 200)
        ) {
          this.infosFormInvalidField.push('age');
        }

        let franceZipCodeRegex = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/;
        let belgiumZipCodeRegex = /^[1-9]{1}[0-9]{3}$/;

        if (
            !this.infosForm.zipCode.match(franceZipCodeRegex) &&
            !this.infosForm.zipCode.match(belgiumZipCodeRegex)
        ) {
          this.infosFormInvalidField.push('zipCode');
        }

        if (!this.infosFormInvalidField.length) {
          this.isRunningAnimation = true;
          this.showSearchLayer = true;
          setTimeout(() => {
            this.currentStep = 5;
            this.showSearchLayer = false;
          }, 5000);
        }
      }
    },
    goToStep6() {
      this.contactFormInvalidField = [];
      if (!this.disableGoToStep6) {
        let frenchRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
        let belgiumRegex = /^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

        if (!this.contactForm.phone.match(frenchRegex) && !this.contactForm.phone.match(belgiumRegex)) {
          this.contactFormInvalidField.push('phone');
        }
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.contactForm.email.match(emailRegex)) {
          this.contactFormInvalidField.push('email');
        }

        if (!this.contactFormInvalidField.length) {
          this.currentStep = 6;
        }
      }
    },
    async sendForm() {
      let url = 'https://urgence-maison-de-retraite.fr/apiForm.php';
      let ipUser = await Axios.get('https://api.ipify.org');

      Axios.get(url, {
        params: {
          inputDep: this.selectedDepartments.map((d) => `${d.value} - ${d.name}`).join(','), // (1) 00 - Name (2+) 01 - Name1,02 - Name2
          cnom: this.contactForm.lastName,
          cprenom: this.contactForm.name,
          cemail: this.contactForm.email,
          contactlink: this.contactForm.relation, // relation.name
          cphone: this.contactForm.phone,
          consentementVal: this.contactForm.conditions ? 1 : 0, // 0 or 1
          source: 'PUFF102',
          emailAR: 'lead64',
          userip: ipUser.data, // get IP from client

          //Premium Fields
          delai_urgence: this.$route.params.deadline,
          ccomment: this.additionalInformation,
          civilite_PA1: this.infosForm.gender,
          paorigname: this.infosForm.usageName,
          panom: this.infosForm.birthName,
          paprenom: this.infosForm.firstName,
          paydate: this.infosForm.age,
          pazipcode: this.infosForm.zipCode,
          pacurrentres: this.infosForm.currentSituation,
          dispo: this.selectedEstablishmentType.value,
          budget: this.selectedBudgetInterval.value,
        },
      }).then(
          () => {
            //Success
            console.log('Success');
            this.unsentForm = false;
            this.$router.push({
              name: 'ThankYou',
              params: { lastName: this.contactForm.lastName, name: this.contactForm.name },
            });
          },
          () => {
            //Error
            console.log('Error');
          }
      );
    },
  },
  beforeCreate(): any {
    if (!this.$route.params.deadline) {
      this.$router.push({ name: 'Home' }).catch(() => {});
    }
  },
  beforeRouteLeave(to, from, next) {
    this.showModalUnsentForm = true;
    if (this.unsentForm) {
      this.unsetFormCallback = () => next();
      next(false);
    } else {
      next();
    }
  },
});
